
.page-header.franchise-page {
	position: relative;
	background: url("../img/page-header-bg.jpg") no-repeat center center;
	background-size: cover;
	padding: 230px 0 100px;
	margin-top: -117px;
}


.franchise-section {
	position: relative;
	padding: 70px 0;
}

@media (max-width:991px) {
	
}
@media (max-width:768px) {
	
}