.page-header.contact-us-page {
	position: relative;
	background: url("../img/inner-banners/Conatct-banner.jpg") no-repeat center center;
	background-size: cover;
	padding: 230px 0 100px;
	margin-top: -117px;
}

.send-msg-section {
	position: relative;
	padding: 50px 0;
}

.send-msg-section .container {
	position: relative;
	z-index: 1;
}

.send-msg-section .section-title {
	text-align: start;
	margin-bottom: 30px;
}

.send-msg-section .send-msg-body {
	padding-right: 20px;
}

.send-msg-section .send-msg-body p {
	font-size: 16px;
	margin-bottom: 30px;
}

.contact-form {
	background-color: var(--background-color);
	border-radius: 30px;
	padding: 40px;
}

.contact-form .form-group .form-control {
	font-size: 16px;
	font-weight: 400;
	line-height: 1.6em;
	color: var(--text-color);
	background-color: transparent;
	border-width: 0 0 1px 0;
	border-color: #c1c1c1;
	border-radius: 0;
	padding: 12px 20px 12px 0;
}

.contact-form .form-group.has-error.has-danger .help-block ul {
	margin-bottom: 0;
}

.contact-form .form-group.has-error.has-danger .help-block ul li {
	font-size: 12px;
	font-weight: 500;
	line-height: 1.2em;
	text-transform: uppercase;
	color: var(--error-color);
	padding-top: 2px;
}

.contact-form .form-group .form-control:focus,
.contact-form .form-group .form-control:focus-visible,
.contact-form .form-group button.btn-default:focus,
.contact-form .form-group button.btn-default:focus-visible {
	outline: none;
	box-shadow: none;
}

.contact-form .button-group {
	position: relative;
}

.contact-form .button-group #msgSubmit,
.booking-form .button-group #msgSubmit {
	/* position: absolute;
	top: 50%;
	left: 200px;
	bottom: auto;
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 0;
	transform: translateY(-50%); */
	font-size: 18px;
	margin-top: 10px;
}

.contact-section {
	background-color: var(--background-color);
	padding: 50px 0;
}

.google-map {
	background: var(--dark-background);
}

.google-map iframe {
	width: 100%;
	height: 330px;
	/* border-radius: 30px; */
}

.contact-detail {
	position: relative;
	background-color: var(--primary-color);
	/* padding: 40px; */
	/* border-radius: 30px; */
	overflow: hidden;
}

/* .contact-detail::before {
	content: '';
	position: absolute;
	top: auto;
	right: 0;
	bottom: 0;
	background: url("../img/contact/contact-detail-bg.png") no-repeat center center;
	background-size: cover;
	width: 141px;
	height: 153px;
} */

.contact-detail * {
	position: relative;
	z-index: 1;
}

.contact-detail h3 {
	font-size: 22px;
	color: var(--dark-background);
	margin-bottom: 20px;
}

.contact-detail ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.contact-detail ul li.list-item {
	padding-bottom: 25px;
	padding-right: 20px;
	margin-bottom: 25px;
	border-bottom: 1px solid #C1C1C1;
	display: flex;
	flex-wrap: wrap;
}

.contact-detail ul li.list-item:last-child {
	padding-bottom: 0;
	margin-bottom: 0;
	border-bottom: none;
}

.contact-detail ul li span.list-icon-img {
	width: 14%;
}

.contact-detail ul li span.list-icon-img img {
	width: 22px;
	height: 25px;
}


.contact-detail ul li span.list-icon-text {
	width: 86%;
}

.contact-detail ul li span.list-icon-text {
	font-size: 16px;
	font-weight: 400;
	color: #777777;
}

.contact-detail ul li span.list-icon-text a {
	display: inline-block;
	color: #777777;
	transition: var(--transition);
}

.contact-detail ul li span.list-icon-text a:hover {
	color: var(--secondary-color);
}

@media (max-width:991px) {
	.send-msg-section {
		padding: 60px 0;
	}

	.send-msg-section .send-msg-body {
		padding-right: 0;
		padding-bottom: 40px;
	}

	.contact-section {
		padding: 60px 0;
	}

	.contact-section .google-map iframe {
		height: 400px;
	}

	.contact-detail {
		margin-bottom: 30px;
	}

	.contact-detail ul li span.list-icon-img {
		width: 6%;
	}

	.contact-detail ul li span.list-icon-text {
		width: 94%;
	}

}

@media (max-width:768px) {
	.contact-form {
		padding: 30px;
	}

	.contact-form .button-group #msgSubmit,
	.booking-form .button-group #msgSubmit {
		/* left: 180px; */
		font-size: 16px;
	}

	.contact-section {
		padding: 50px 0;
	}

	.contact-section .google-map iframe {
		height: 300px;
	}

	.contact-detail {
		padding: 40px;
		margin-bottom: 0;
		padding-bottom: 20px;
	}

	.contact-detail h3 {
		font-size: 20px;
	}

	.contact-detail ul li span.list-icon-img {
		width: 14%;
	}

	.contact-detail ul li span.list-icon-text {
		width: 86%;
	}

}

/* captcha css */
@media (min-width:450px) {
	.captchdisplay {
		display: flex;
	}

	.captchvaluesize {
		width: 50% !important;
		height: 60px !important;
		font-size: 16px !important;
	}
}

@media (max-width:450.9px) {
	.captchdisplay {
		display: block;
	}

	.captchvaluesize {
		width: 100% !important;
		height: 60px !important;
		font-size: 16px !important;
	}
}

.captcha-copy-disable {
	user-select: none !important;
	background: #ffffffe0;
}

.iorotate {
	transition: 1s ease all;
	-moz-transition: 1s ease all;
	-webkit-transition: 1s ease all;
	transform: rotate(360deg) !important;
	color: black !important;
}

.ioSyncbtn {
	transition: 1s ease all;
	-moz-transition: 1s ease all;
	-webkit-transition: 1s ease all;
	color: black !important;
	font-size: 26px !important;
}