
.restaurant-inforamtion {
	background: url('../img/offers/restaurants-wrap-bg.png') no-repeat center right;
	background-size: auto;
	padding-top: 50px;
}

.restaurant-info-box {
	/* background: url('../img/offers/restaurants-bg.png') no-repeat center right; */
	/* background-size: cover; */
	/* background-color: var(--dark-background); */
	/* display: flex;
	flex-wrap: wrap;
	align-items: center;	 */
	/* justify-content: center; */
	border-radius: 30px;
	/* padding: 35px 50px; */
	/* padding: 2rem; */
	margin-bottom: 60px;
	position: relative;
	overflow: hidden;
}
.restaurant-info-box img{
	/* position: absolute;
	top: 0;
	left: 0; */
	width: 100%;
	height: 100%;
	/* aspect-ratio: 1/1; */
}
.restaurant-info-box .info-btn{
	position: absolute;
	bottom: 1rem;
	right: 1rem;
}
.restaurant-info-box .info-box-content {
	/* width: 55%; */
}

.info-box-content .section-title {
	margin-bottom: 0;
}

.info-box-content .section-title h2 {
	text-align: start;
	color: var(--primary-color) !important;
	margin-bottom: 30px;
}

.info-box-content p {
	width: 520px;
	color: var(--dark-text-color);
}

.info-box-img {
	width: 45%;
}

.info-btn {
	margin-top: 30px;
}

.info-btn .btn-default {
	color: var(--primary-color);
	border-color: var(--primary-color);
	margin-right: 25px;
}

.info-btn .btn-default:last-child {
	margin-right: 0;
	margin-bottom: 0;
}

.info-btn .btn-default::before {
	border-color: var(--primary-color);
}

.counter-bar {
	position: relative;
	border-radius: 30px;
	margin-bottom: 30px;
	transition: all 0.5s ease-in-out;
	overflow: hidden;
}

.counter-bar::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: #00000099;
	background-size: cover;
	border-radius: 30px;
	z-index: 1;
}

.counter-bar:hover .counter-bar-img img{
	transform: scale(1.1);
}

.counter-bar-img img {
	width: 100%;
	transition: all 0.5s ease-in-out;
}

.counter-bar .counter-bar-body {
	position: absolute;
	top: 50%;
	left: 0;
	text-align: center;
	color: var(--primary-color);
	width: 100%;
	transform: translateY(-50%);
	z-index: 3;
}

.counter-bar-body h3 {
	font-size: 46px;
	color: var(--primary-color) !important;
	margin-bottom: 0;
}

.counter-bar-body p {
	font-size: 16px;
}
@media (max-width:991px) {
    .restaurant-inforamtion {
		padding: 60px 0 30px;
	}
	
	.restaurant-info-box {
		padding: 50px 40px 30px;
		margin-bottom: 40px;
	}
	
	.restaurant-info-box .info-box-content ,
	.info-box-img {
		text-align: center;
		width: 100%;
	}
		
	.info-box-content .section-title h2 {
		text-align: center;
	}

	.restaurant-info-box p {
		width: 100%;
	}

	.info-btn .btn-default {
		margin-right: 20px;
	}

	.info-box-img {
		margin-top: 30px;
	}
	.info-box-img img{
		width: 100%;
	}
	
	.counter-bar-body h3 {
		font-size: 36px;
	}

}
@media (max-width:768px) {
	.restaurant-inforamtion {
		padding: 60px 0 30px;
	}
	
	.restaurant-info-box {
		padding: 35px 35px 30px;
		margin-bottom: 30px;
	}

	.info-box-content .section-title h2 {
		text-align: center;
		margin-bottom: 20px;
	}

	.info-btn .btn-default {
		margin-right: 0;
		margin-bottom: 15px;
	}

	.counter-bar,
	.counter-bar::before {
		border-radius: 20px;
	}

	.counter-bar-img img {
		height: 100px;
		object-fit: cover;
	}
	
	.counter-bar-body h3 {
		font-size: 30px;
	}

}