.design-two .hero {
	min-height: 105vh;
	background: url('../img/home-banner/home-banner.jpg') no-repeat top;
	background-size: cover;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 150px 0;
	margin-top: -100px;
}

.design-two .hero::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: #00000085;
	width: 100%;
	height: 100%;
}

.design-two .hero .container {
	position: relative;
	z-index: 1;
	max-width: 780px;
}

.design-two .hero .section-title {
	margin-bottom: 30px;
}

.design-two .hero .section-title h3,
.design-two .hero .section-title h1,
.design-two .hero  p {
	color: var(--theme-color-white) !important;
}
.design-two .hero .section-title h3,
.design-two .hero .section-title h1{
line-height: normal !important;
}
.design-two .hero .section-title h1 {
	font-size: 60px;
}

.design-two .hero-content p {
	text-align: center;
	max-width: 550px;
	margin: 0 auto 30px;
	font-size: 1.3rem;
}

.design-two .hero-footer {
	max-width: 400px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.design-two .hero .btn-book-now {
	color: var(--theme-color-white) !important;
	border-color: var(--theme-color-white) !important;
}

.design-two .hero .btn-order-online {
	border-color: var(--btn-hover-color);
	color: var(--theme-color-white) !important;
}

.design-two .hero .btn-book-now::before,
.design-two .hero .btn-order-online::before {
	border:2px solid var(--theme-color-white) !important;
    background: transparent;
}

.design-two .hero .btn-book-now:hover,
.design-two .hero .btn-order-online:hover {
	color: var(--accent-color);
	border-color: var(--color-theme) !important;
}
.design-two .hero .btn-book-now:hover::before{
	color: var(--theme-color-white) !important;
}
.design-two .down-arrow a {
	position: absolute;
	top: auto;
	left: 50%;
	bottom: 30px;
	width: 24px;
	height: 36px;
	border: 2px solid var(--theme-color-white) !important;
	border-radius: 100px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	transform: translate(-50%);
	animation: jumpInfinite 1.5s infinite;
}

.design-two .down-arrow a img {
	width: 12px;
	height: 24px;
}
  
@keyframes jumpInfinite {
	0% {
		margin-bottom: 0;
	}
	50% {
		margin-bottom: 20px;
	}
	100% {
		margin-bottom: 0;
	}
}
@media only screen and (max-width: 991px) {
	.design-two .hero{
		min-height: 70vh;
	}
    .design-two .section-title {
		margin-bottom: 40px;
	}
	
	.design-two .section-title h2 {
		font-size: 36px;
	}

	.design-two .hero {
		margin-top: -98px;
	}

	.design-two .hero .section-title h1 {
		font-size: 50px;
	}

	.design-two .hero-content p {
		max-width: 650px;
	}

}

@media only screen and (max-width: 767px){
	.design-two .hero .section-title h1 {
		font-size: 38px;
	}
}
@media only screen and (max-width: 568px){
	.design-two .hero {
		height: 96vh;
	}
}
@media only screen and (max-width: 380px){
	.design-two .hero {
		height: 100vh;
	}
}