.design-two .why-choose-us {
	background: var(--background-color);
	padding: 50px 0;
	position: relative;
}

.design-two .why-choose-us::before {
	content: '';
	position: absolute;
	top: auto;
	left: 0;
	bottom: 0;
	background: url("../img/catering/why-choose-us-bg.png") no-repeat;
	background-size: cover;
	width: 300px;
	height: 500px;
}

.design-two .why-choose-us .container {
	position: relative;
	z-index: 1;
}

.design-two .why-choose-us .col-md-6.col-12:hover .wcu-img-box {
	background-color: var(--primary-color);
}

.design-two .why-choose-us .section-title h2 {
	/* max-width: 550px; */
	margin: 0 auto;
}
 
.design-two .wcu-img-box {
	border-radius: 30px;
	text-align: center;
	padding: 30px 20px;
}

.design-two .image-box figure {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50%;
	margin: 0 auto 20px;
}

.design-two .image-box figure::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	background-color: #FEBC301A;
	border-radius: 100%;
	width: 70px;
	height: 70px;
	transform: translate(35px, -12px);
}

.design-two .image-box img {
	width: 80px;
	height: 80px;
}

.design-two .image-body h3 {
	font-size: 22px;
	color: var(--accent-color);
	margin-bottom: 20px;
}

.design-two .image-body p {
	font-size: 16px;
	color: var(--text-color);
}

@media (max-width:991px) {
	.design-two .why-choose-us {
		padding: 60px 0;
	}
	
	.design-two .why-choose-us::before{
		width: 200px;
		height: 340px;
	}

	.design-two .why-choose-us .section-title h2 {
		max-width: 450px;
	}
	
	.design-two .wcu-img-box {
		padding: 30px 20px 20px;
		border-radius: 20px;
		height: 100%;
	}
	
	.design-two .image-box figure {
		width: 60%;
		margin: 0 auto 20px;
	}
	
	.design-two .image-box figure::before {
		width: 60px;
		height: 60px;
		transform: translate(5px, -10px);
	}
	
	.design-two .image-box img {
		width: 70px;
		height: 70px;
	}
	
	.design-two .image-body h3 {
		font-size: 20px;
	}
    
}
