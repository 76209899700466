.testimonials-section {
  position: relative;
  background-color: var(--dark-background);
  padding: 70px 0;
  background: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)), url(../img/testimonial/testimonial-bg.jpg) ;
  background-size: cover;
  /* height: 80vh; */
}
.testimonials-section .slick-prev:before,.testimonials-section .slick-next:before{
  display: none;
}
.testimonials-section .testimonial-slide{
  border: 1px solid #191919;
  background: #000000b4;
  padding: 1rem;
  margin: 10px;
  border-radius: 22px;
  height: 100%;
}

.testimonials-section .slick-track
{
    display: flex !important;
}

.testimonials-section .slick-slide
{
  height: inherit !important;
}
.testimonials-section .slick-slide > div{
  height: 100%;
}
.testimonials-section .slick-slider .slick-track{
  padding-bottom: 2rem;
}
/* .testimonials-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: url("../img/testimonial/testimonial-bg.png") no-repeat bottom left;
  background-size: cover;
  width: 214px;
  height: 100%;
} */

.testimonials-section .container {
  position: relative;
  z-index: 1;
}

.testimonials-section .section-title {
  text-align: start;
  margin-bottom: 30px;
}

.testimonials-section .section-title h3,
.testimonials-section .section-title h2 {
  color: var(--primary-color) !important;
}

.testimonial-img {
  text-align: end;
}

.testimonial-slider-wrapper .swiper-wrapper {
  padding-bottom: 60px;
}

.testimonial-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.testimonial-body p {
  color: var(--dark-text-color);
  margin: 0;
}

.testimonial-footer h3 {
  font-family: var(--default-font) !important;
  font-size: 22px !important;
  color: var(--primary-color) !important;
}

.testimonial-footer p {
  font-size: 14px;
  color: var(--primary-color);
}

/* .slick-prev,
.slick-next {
  bottom: 20px;
} */

.slick-prev,
.slick-next {
  width: 36px;
  height: 36px;
  background-color: var(--btn-hover-color);
  color: var(--dark-background);
  border: 2px solid var(--btn-hover-color);
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  margin-top: 7rem;
  bottom: 0;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: var(--primary-color);
  background-color: #ffffff00;
  border-color: var(--primary-color);
}

.slick-next {
  left: 55px !important;
}

.slick-prev {
  left: 0 !important;
}

.slick-next::after,
.slick-prev::after {
  font-family: "FontAwesome";
  font-size: 18px;
  font-weight: 900;
}

.slick-next::after {
  content: "\f061";
}

.slick-prev::after {
  content: "\f060";
}

@media (max-width: 991px) {
  .testimonials-section {
    padding: 60px 0 70px;
  }

  .testimonials-section::before {
    width: 170px;
    height: 430px;
  }
}
@media (max-width: 768px) {
  .testimonials-section {
    padding: 50px 0 70px;
  }

  .testimonials-section::before {
    display: none;
  }

  .testimonials-section .section-title,
  .testimonials-section .testimonial-footer,
  .testimonial-slide {
    text-align: center !important;
  }

  .testimonial-body {
    padding-right: 0;
  }

  .testimonial-footer h3 {
    font-size: 20px;
  }

  .slick-prev {
    left: 43% !important;
    transform: translateX(-43%);
  }

  .slick-next {
    left: 58% !important;
    transform: translateX(-58%);
  }

  .testimonial-img {
    margin-top: 100px;
    text-align: center;
  }

  .testimonial-img img {
    max-width: 50%;
  }
  .testimonial-slide {
    padding: 0 10px;
  }
  .slick-prev,
  .slick-next {
    margin-top: 7rem;
  }
}
