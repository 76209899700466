.design-two .home-about {
	padding: 110px 0 50px;
	background: var(--primary-color);
	position: relative;
}

.design-two .home-about::before{
	content: '';
	position: absolute;
	top: 50%;
	right: 0;
	bottom: auto;
	background: url('../img/about/home-about-bg.png') no-repeat;
	width: 465px;
	height: 610px;
	transform: translateY(-50%);
}

.design-two .home-about .container {
	position: relative;
	z-index: 1;
}

.design-two .home-about-left {
	position: relative;
}

.design-two .home-about-left .home-about-img {
	display: inline-flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

.design-two .home-about-left .home-about-img figure {
	width: 48%;
}

.design-two .home-about-left .home-about-img figure img {
	width: 100%;
	border-radius: 30px;
}

.design-two .home-about-left .home-about-since {
	position: absolute;
	top: 0;
	right: 0;
	background-color: var(--btn-hover-color);
	border: 10px solid var(--primary-color);
	border-radius: 50%;
	width: 190px;
	height: 190px;
	transform: translate(50px, -70px);
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	align-content: center;
	justify-content: center;
	text-align: center;
	z-index: 1;
}

.design-two .home-about-since h3 {
	font-size: 24px;
	color: var(--primary-color) !important;
	width: 100%;
	margin-bottom: 10px;
    font-family: var(--accent-font) !important;
}

.design-two .home-about-since h2 {
	font-size: 46px;
	color: var(--primary-color);
	width: 100%;
	margin-bottom: 0;
}

.design-two .home-about-right {
	padding-left: 60px;
}

.design-two .home-about-right .section-title {
	text-align: start;
	margin-bottom: 20px;
}

.design-two .home-about-right p {
	margin-bottom: 30px;
}

.design-two .about-icon-box {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.design-two .about-icon-box .icon-box {
	width: 47%;
	background: var(--primary-color);
	border-radius: 10px;
	padding: 30px;
	margin-bottom: 30px;
	box-shadow: 0px 0px 30px 0px #0000000D;
}

.design-two .icon-box .icon-box-img {
	margin-bottom: 20px;
}
.design-two .icon-box .icon-box-img img{
	width: 50px !important;
	height: 50px !important;
}

.design-two .icon-box .icon-box-content h3 {
	font-size: 20px;
	color: var(--accent-color);
}

.design-two .icon-box .icon-box-content p {
	margin-bottom: 0;
}


.design-two .image-anime{
	position: relative;
	overflow: hidden;
}

.design-two .image-anime:after{
	content: "";
	position: absolute;
    width: 300%;
    height: 0%;
    left: 50%;
    top: 50%;
    background-color: rgba(255,255,255,.2);
    transform: translate(-50%,-50%) rotate(-45deg);
    z-index: 1;
}

.design-two .image-anime:hover:after {
    height: 250%;
    transition: all 600ms linear;
    background-color: transparent;
}

@media (max-width:991px) {
    .design-two .home-about {
        padding: 90px 0 60px;
	}
    
    .design-two	.home-about-left {
        text-align: center;
	}
    
    .design-two	.home-about-left .home-about-img {
		width: 100%;
	}
    
    .design-two	.home-about-left .home-about-since {
		width: 180px;
		height: 180px;
		transform: translate(10px, -70px);
	}
    
    .design-two	.home-about-since h3 {
        font-size: 22px;
	}
    
    .design-two	.home-about-since h2 {
        font-size: 42px;
	}
    
    .design-two	.home-about-right {
        padding-top: 40px;
		padding-left: 0;
	}
}
@media (max-width:768px) {
    .design-two .home-about {
		padding: 60px 0 50px;
	}

    .design-two	.home-about-left .home-about-img {
		width: 100%;
	}

    .design-two	.home-about-left .home-about-since {
		border-width: 6px;
		width: 105px;
		height: 105px;
		transform: translate(12px, -35px);
	}

    .design-two	.home-about-since h3 {
		font-size: 14px !important;
		margin-bottom: 5px;
        line-height: normal;
	}

    .design-two	.home-about-since h2 {
		font-size: 28px !important;
        line-height: normal;
	}

    .design-two	.home-about-right {
		padding-top: 30px;
		padding-left: 0;
	}
	.design-two .about-icon-box{
		flex-direction: column;
	}
    .design-two	.about-icon-box .icon-box {
		width: 100%;
		padding: 20px;
		text-align: center;
	}

    .design-two	.icon-box .icon-box-content h3 {
		font-size: 16px;
	}

    .design-two	.icon-box .icon-box-content p {
		font-size: 14px;
	}

}