.page-header.catering-page {
  position: relative;
  background: url("../img/inner-banners/Catering-banner.jpg") no-repeat center center;
  background-size: cover;
  padding: 230px 0 100px;
  margin-top: -117px;
}

.franchise-section {
  position: relative;
  padding: 40px 0;
}
.catering-inner-img {
  height: 100%;
}
.catering-inner-img img {
  width: 100%;
  height: 100%;
  /* aspect-ratio: 1/1; */
  object-fit: cover;
  border-radius: 30px;
}
.catering-inner-card h3 {
  font-size: 2rem;
}
.catering-inner-card ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.catering-inner-card ul li {
  font-size: 16px;
  font-weight: 400;
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}
.catering-inner-card ul li::before {
  content: "\f058";
  position: absolute;
  top: 50%;
  left: 0;
  bottom: auto;
  font-family: "Font Awesome 6 Free";
  font-size: 16px;
  font-weight: 900;
  color: var(--secondary-color);
  transform: translateY(-50%);
}
@media (max-width: 991px) {
  .catering-inner-img img {
    aspect-ratio: 1/1;
  }
}
@media (max-width: 768px) {
}
