.testimonial-section .react-multi-carousel-dot button {
  border-color: #fff !important;
}

.testimonial-section .react-multi-carousel-dot--active button {
  background: #fff !important;
}

.testimonial-slid {
  color: #fff;
}

.testimonial-slid p {
  text-align: center !important;
  font-size: 1.1rem;
}

.react-multi-carousel-list {
  padding-bottom: 0;
}

@media (min-width: 992px) {
  .testimonial-slid p {
    font-size: 1.8rem;
  }

  .testimonial-slid span {
    font-size: 1.3rem;
  }
}

.design-two .image-gallery .gallery-img-border-radius {
  border-radius: 30px !important;
}

.gallery-lrft-img {
  /* height: 94% !important; */
  aspect-ratio: 1/1;
  width: 100%;
}

.imagegallery-section .slick-next,
.imagegallery-section .slick-prev,
.imagegallery-section .slick-dots {
  display: none !important;
}

@media (max-width:568px) {
  .design-two .image-gallery .gallery-img-border-radius {
    aspect-ratio: 1/1;
    height: auto !important;
  }

  .gallery-lrft-img {
    margin-bottom: 1.4rem !important;
  }
}