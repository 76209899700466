body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}

/* code { 
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.date-dropdown-style .MuiInputBase-root{
  height: 40px;
  width: 150px;
}
.date-dropdown-style .css-1869usk-MuiFormControl-root{
min-width: 80px !important;
}
.calender-icon-size{
width: 30px !important;
height: 30px !important;
}
.calender-style .MuiFormControl-root{
  min-width: 150px !important;
}

.disabled-btn button{
  background-color: #bcb8b8 !important; 
  color: rgba(255, 255, 255, 0.703) !important;
}


/* Disable zooming on Safari */
@media (max-width: 767px) {
  html,body {
      touch-action: manipulation !important;
  }
}
