
.design-two .most-popular-section{
	background: url('../img/populardishes/most-popular-dises-bg.png') no-repeat bottom right;
	background-size: 500px;
	padding: 50px 0;
}
.most-popular-section .slick-track
{
    display: flex !important;
}

.most-popular-section .slick-slide
{
  height: inherit !important;
}
.most-popular-section .slick-slide > div{
	height: 100%;
}
.most-popular-section .slick-slide > div > .px-3{
	height: 100%;
}
.design-two .most-popular-section .slick-dots{
	bottom: -50px !important;
}
.design-two .most-popular-section .slick-prev, .design-two .most-popular-section .slick-next{
	display: none !important;
}

.design-two .most-popular-section .slick-dots li button:before{
	font-size: 1rem;
	color: var(--background-color) !important;
	opacity: 1;
}
.design-two .most-popular-section .slick-dots li.slick-active button:before{
	color: var(--color-theme) !important;
}

.design-two .menu-item-box {
	margin-bottom: 25px;
}

.design-two .menu-item {
	background-color: var(--background-color);
	border-radius: 30px;
	overflow: hidden;
	transition: all 0.5s ease-in-out;
	height: 100%;
}
.design-two .menu-item-img .image-anime{
	margin-bottom: 0;
}
.design-two .menu-item:hover .menu-item-img img{
	transform: scale(1.1);
}

.design-two .menu-item:hover .menu-item-img .image-anime:after{
	height: 250%;
    transition: all 600ms linear;
    background-color: transparent;
}

.design-two .menu-item-img img {
	width: 100%;
	border-radius: 30px 30px 0 0;
	transition: all 0.5s ease-in-out;
	aspect-ratio: 1/1;
}

.design-two .menu-item-body {
	padding: 15px 20px 20px;
}
.design-two .menu-item-body p{
	margin: 0;
}

.design-two .menu-item-body .rating-img {
	margin-bottom: 10px;
}

.design-two .menu-item-body .rating-img img {
	width: 94px;	
	height: 18px;
}

.design-two .menu-item-body h3 {
	font-size: 17px;
	color: var(--accent-color);
	margin: 0;
	text-align: center;
	font-weight: normal;
}
@media (max-width:991px) {
	
    .design-two .most-popular-section .slick-dots{
		left: 0 !important;
	}
}
