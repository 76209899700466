.gallery-page{
  background: url(../img/inner-banners/Gallery-banner.jpg) center;
  background-size: cover;
}
.gallery-page::before {
  opacity: 65%;
}
.Gallary-images img {
  width: 100% !important;
  border-radius: 22px;
}

.pswp img {
  object-fit: contain !important;
}

.ant-image .ant-image-mask {
  border-radius: 22px !important;
}