@import url("https://fonts.googleapis.com/css2?family=Domine:wght@400..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Domine:wght@400..700&family=Inter:wght@100..900&display=swap");
:root {
  --primary-color: #ffffff;
  --secondary-color: #febc30;
  --text-color: #808080;
  --accent-color: #222222;
  --background-color: #f7f4ed;
  --dark-background: #000000;
  --dark-text-color: #d3d3d3;
  --error-color: #ff0000;
  --default-font: "Inter", sans-serif;
  --accent-font: "Domine", serif;
  --transition: all 0.3s ease-in-out;
  --ticker-gap: 40px;
  --photo-gap: 15px;
  --text-color: #808080;
}
.design-two {
  font-family: var(--default-font);
}
.design-two .section-padding {
  position: relative;
  padding: 70px 0;
}
.design-two :where(h1, h2, h3, h4, h5, h6) {
  font-family: var(--accent-font) !important;
  line-height: normal;
}
.design-two .section-title {
  margin-bottom: 60px;
  text-align: center;
  /* line-height: normal !important; */
}

.design-two .section-title h3 {
  font-family: var(--default-font) !important;
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: var(--text-color) !important;
  margin-bottom: 15px;
  line-height: normal !important;
}

.design-two .section-title h3::before {
  content: "";
  position: absolute;
  top: auto;
  right: -8px;
  bottom: 3px;
  background-color: var(--color-theme);
  color: var(--color-theme);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  line-height: normal !important;
}

.design-two .section-title h2 {
  font-size: 45px;
  color: var(--accent-color) !important;
  line-height: 1.2em !important;
  margin-bottom: 20px !important;
  font-family: var(--accent-font) !important;
  text-transform: inherit;
}

.design-two .btn-default {
  display: inline-block;
  font-family: var(--default-font) !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.1em;
  color: var(--color-theme);
  background: transparent;
  text-align: start;
  padding: 16px 60px 16px 20px;
  border: 2px solid var(--color-theme);
  border-radius: 100px;
  transition: var(--transition);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.design-two .btn-default::before {
  content: "\f061";
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  font-family: "FontAwesome";
  font-size: 16px;
  font-weight: 900;
  width: 36px;
  height: 36px;
  color: var(--theme-color-white);
  background-color: var(--color-theme);
  border: 1.5px solid var(--color-theme);
  border-radius: 50%;
  transition: var(--transition);
  transform: translate(-6px, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.design-two .btn-default::after {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  background: var(--color-theme);
  border-radius: 30px;
  transition: var(--transition);
}

.design-two .btn-default:hover {
  color: var(--theme-color-white);
}
.design-two .btn-default:hover::after {
  width: 110%;
  left: -10px;
}

.design-two .btn-default:hover::before {
  background-color: var(--primary-color);
  border-color: var(--color-theme);
  color: var(--color-theme);
}
.page-header {
  position: relative;
  background: url("./img/page-header-bg.jpg") no-repeat center center;
  background-size: cover;
  padding: 230px 0 100px;
  margin-top: -117px;
}
.page-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--dark-background);
  width: 100%;
  opacity: 50%;
}

.page-header .container {
  position: relative;
  z-index: 1;
}

.page-header-box {
  text-align: center;
  color: var(--primary-color);
}

.page-header-box h1 {
  font-size: 60px;
}

.page-header-box nav ol {
  justify-content: center;
  color: var(--primary-color);
  margin-bottom: 0;
  display: none;
}

.page-header-box nav ol .breadcrumb-item {
  font-size: 16px;
  font-weight: 400;
  color: var(--primary-color);
  transition: var(--transition);
}

.page-header-box nav ol .breadcrumb-item.active {
  font-weight: 600;
  color: var(--primary-color);
}

.page-header-box nav ol .breadcrumb-item a {
  color: inherit;
}

.page-header-box nav ol .breadcrumb-item a:hover {
  color: var(--secondary-color);
}

.page-header-box nav ol .breadcrumb-item::before {
  color: var(--primary-color);
}
.terms-content{
  list-style: none;
}
.terms-content li{
  margin-bottom: 1rem;
  display: flex;
}
@media (max-width: 991px) {
  .section-title {
    margin-bottom: 40px;
  }

  .design-two .section-title h2 {
    font-size: 36px !important;
  }
}
@media (max-width: 768px) {
  .design-two .section-title h2 {
    font-size: 30px !important;
  }
}
