.design-two .logo-size {
  width: auto;
  height: 75px;
  border-radius: 0;
  transform: translateY(0);
  padding: 0;
  margin: 0;
  object-fit: contain;
  /* transform: translate(-25px,-3px); */
}
.design-two header.main-header {
  position: relative;
  max-width: 1350px;
  background: var(--primary-color);
  border-bottom: 1px solid transparent;
  border-radius: 100px;
  padding: 0;
  margin: 0 auto;
  z-index: 100;
  top: 30px;
  /* padding-top: 30px; */
}
.design-two .gap-nav-list {
  gap: 0.5rem !important;
}

.design-two header.main-header .header-sticky {
  position: relative;
  top: 0;
  padding: 0 10px;
  z-index: 100;
}

.design-two header.main-header .header-sticky.hide {
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

.design-two header.main-header .header-sticky.active {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(0);
  border-bottom: 1px solid #22222226;
  background-color: var(--primary-color);
  padding: 0;
  margin-top: 0;
  animation: navbackground 1s ease-out;
}
.order-online-page .design-two header.main-header .header-sticky.active {
  display: none;
}

@keyframes navbackground {
  0% {
    background: #ffffff7c;
    transform: translateY(-100px);
  }

  100% {
    background: #ffffff;
    transform: translateY(0px);
  }
}
.design-two .navbar {
  padding: 0;
}

.design-two .navbar-brand {
  padding: 0;
}
.design-two .navbar-link-color .nav-link {
  color: var(--theme-color-black) !important;
  padding: 6px 0.8rem !important;
}
.design-two .navbar-link-color a {
  border-radius: 30px;
}
.design-two .navbar-link-color a:hover {
  background: var(--color-theme);
  color: var(--theme-color-white) !important;
}

.design-two .onliner-order-desktop-btn {
  font-size: 0.9rem !important;
  padding: 8.5px 50px 8.5px 10px !important;
  color: var(--color-theme) !important;
}

.design-two .onliner-order-desktop-btn::before {
  width: 25px !important;
  font-size: 14px !important;
  height: 25px !important;
}
.design-two .navbar-toggler-icon {
  display: none;
}
.design-two .navbar-toggler {
  border: none;
  padding: 0;
}
.design-two .navbar-toggler svg,
.design-two .order-online-location svg {
  width: 40px;
  height: 40px;
  background: var(--color-theme);
  border-radius: 100px;
  padding: 8px;
  margin: 0;
  color: var(--theme-color-white);
}
.pc-sm-icon {
  list-style: none;
}
.pc-sm-icon li a {
  width: 40px;
  height: 40px;
  color: var(--theme-color-white) !important;
  background: var(--color-theme);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.pc-sm-icon li:last-child a {
  margin-right: 0;
}
.phn-online-btn {
  background-color: var(--color-theme);
  color: #fff !important;
  border-color: var(--color-theme) !important;
}
.order-online-btn.fixed-bottom {
  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (max-width: 1099px) {
  .gap-nav-list {
    gap: 0.6rem !important;
  }
}
@media (max-width: 992px) {
  .phn-view-sidebar .offcanvas-header {
    justify-content: space-between;
  }
  .phn-view-sidebar .offcanvas-header .btn-close {
    margin: 0;
  }
}

/* @media (max-width: 992px) { */
@media (max-width: 1200px) {
  .toggle-position-phnview {
    transform: translateY(30px);
  }

  .nav-brand-size {
    right: 0;
    transform: translateY(-33px);
  }

  .navbar-link-color a {
    color: #000 !important;
  }

  .navbar-link-color {
    padding-left: 10px;
    /* font-size: 23px; */
    line-height: 0.8rem;
  }

  .toggle-position-phnview .navbar-toggler {
    transform: translateY(-30px) !important;
  }

  .logo-size {
    height: 100px;
    padding: 0px;
    /* border-radius: 50%;
    transform: translateY(-17px); */
  }
  /* .mobile-review-box .react-stars span{
    color: gray !important;
  } */
}

/* @media (min-width: 992px) { */
@media (min-width: 1200px) {
  .navbar-link-color a {
    color: #fff !important;
  }

  .navbar-link-color {
    align-items: center;
  }
}

/* phoneview cross */
.phn-view-sidebar > .btn-close {
  transform: translate(-20px, -20px);
  font-size: 25px;
}
.toast-header .btn-close {
  margin: 0 !important;
}
.mobile-review-box {
  display: none;
}
.position-fixed.bottom-0 {
  z-index: 9999 !important;
}
.desk-sm-icon {
  display: flex;
  list-style: none;
  padding-left: 0;
}

.desk-sm-icon a {
  width: 35px;
  height: 35px;
  background: var(--color-theme) !important;
  color: var(--theme-color-white) !important;
  margin-right: 1rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}
.desk-sm-icon li:last-child a {
  margin-right: 10px;
}
.navbar-link-color .desk-sm-icon a:hover {
  background: transparent !important;
  color: var(--color-theme) !important;
}
@media (min-width: 993px) {
  .btn-grub.w-auto.cursor-pointer {
    width: 35px !important;
    height: 35px;
    background: var(--color-theme) !important;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    padding: 0;
  }
  .btn-grub.w-auto.cursor-pointer:hover {
    background: transparent !important;
    color: var(--color-theme) !important;
  }
}

@media (max-width: 992px) and (min-width: 568px) {
  .recipe-img-wrapper-tab-view {
    width: 100%;
  }
  .recipe-img-wrapper-tab-view img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    margin-bottom: 1rem;
  }
  .food-type-filter-row {
    padding-bottom: 1rem !important;
  }
  .item-category-filter {
    max-width: fit-content !important;
  }
  .recent-order-box .card-text {
    overflow-x: auto;
  }
  .order-list-view-main {
    width: 200%;
  }
  /* .order-list-view-box, */
  /* .order-list-view-box-no{
      max-width: fit-content !important;
    } */
}
/* @media (max-width: 992px) { */
@media (max-width: 1200px) {
  .design-two .navbar-link-color .nav-link {
    padding: 0 0.5rem !important;
    align-items: center;
  }
  .main-header .container {
    max-width: 100% !important;
  }
  .mobile-sm-icon {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin-top: 0.5rem;
  }

  .mobile-sm-icon a {
    width: 35px !important;
    height: 35px;
    background: var(--color-theme) !important;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    padding: 0;
    color: var(--theme-color-white) !important;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
  .mobile-call-icon {
    color: #000;
  }
  .mobile-call-icon svg {
    margin-top: 0;
    width: 35px !important;
    height: 35px;
    background: var(--color-theme) !important;
    transition: 0.3s;
    padding: 0;
    color: var(--theme-color-white) !important;
    border-radius: 50%;
    margin-right: 0.5rem;
    padding: 6px;
  }
  .mobile-review-box .btn-grub {
    display: flex;
    flex-direction: column;
    color: var(--color-theme) !important;
    background: transparent !important;
    text-align: center;
  }
  .mobile-review-box p {
    text-align: center;
    font-weight: bold;
    font-size: 0.9rem;
    color: var(--color-theme) !important;
  }
}
.design-two .order-online-location {
  display: none;
}
@media (max-width: 500px) {
  .design-two header.main-header .header-sticky{
    padding: 0;
  }
  .design-two .navbar-brand {
    margin: 0;
  }
  .order-online-page .design-two .order-online-location svg {
    background: transparent;
    color: var(--color-theme);
    padding: 0 !important;
  }
  .order-online-page .design-two .order-online-location {
    display: block;
  }
  .order-online-page .design-two header.main-header .header-sticky,
  .order-online-page .design-two header.main-header {
    margin: 0;
    padding: 0;
  }
  .order-online-page .design-two .navbar-toggler svg,
  .order-online-page .design-two .order-online-location svg {
    width: 35px;
    height: 35px;
  }
  .phn-view-sidebar {
    max-width: 70% !important;
  }
  .navbar-link-color {
    font-size: 1rem;
  }
  .masla-of-india-home .toggle-position-phnview .navbar-toggler {
    transform: translateY(20px) !important;
  }
  .fixed-top.top-bar .navbar-toggler {
    transform: translateY(0) !important;
    /* position: static !important; */
  }
  .logo-size {
    width: 90px;
    height: 90px;
    padding: 0px;
    /* border-radius: 50%; */
    object-fit: contain;
    /* transform: translateY(-11px); */
  }
  .toggle-position-phnview,
  .nav-brand-size,
  .toggle-position-phnview .navbar-toggler {
    transform: translateY(0) !important;
  }
  .nav-brand-size .logo {
    transform: translateY(-15px);
  }
  .navbar-link-color .onliner-order-mobile {
    color: #fff !important;
  }

  .mobile-nav-heading {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--color-theme);
  }
}

@media (min-width: 500px) and (max-width: 1200px) {
  .mobile-nav-heading {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--color-theme);
  }
}

@media (max-width: 360px) {
  .phn-view-sidebar {
    max-width: 80% !important;
  }
}
@media (max-width: 310px) {
  .phn-view-sidebar {
    max-width: 90% !important;
  }
}
.onliner-order-mobile {
  border-radius: 8px !important;
}

@media (min-width: 501px) and (max-width: 1200px) {
  .btn-tab-font {
    font-size: 23px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1280px) {
  .gap-nav-list {
    gap: 0.5rem !important;
  }
}

@media (min-width: 1280px) {
  .gap-nav-list {
    gap: 1.5rem !important;
  }
}

@media (max-width: 992px) {
  .design-two .logo-size {
    height: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .design-two .phn-online-btn {
    border-radius: 100px;
    background: var(--color-theme);
  }
  .design-two header.main-header {
    margin: 0 15px;
  }
}
